import React, { Component } from "react";
import {
    MDBModalBody,
    MDBModalHeader,
    MDBSelect,
    MDBSelectInput,
    MDBSelectOptions,
    MDBSelectOption,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBModal,
} from "mdbreact";
import OperatorInfo from "../../../../components/NetworkMgmt/Settings/OperatorInfo";
import EmailInfo from "../../../../components/NetworkMgmt/Settings/EmailInfo";
import SMSInfo from "../../../../components/NetworkMgmt/Settings/SMSInfo";
import TandCInfo from "../../../../components/NetworkMgmt/Settings/TandCInfo";
import DisplayOptionsInfo from "../../../../components/NetworkMgmt/Settings/DisplayOptionsInfo";
import axios from "../../../../axios";
import { withTranslation } from "react-i18next";
class PropertyManagementNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalAddStaff: false,
            modalModifyStaff: false,
            loading: false,
            building: "All",
        };
        this.handleEditorChange = this.handleEditorChange.bind(this);
    }
    componentDidMount() {
        this.getVenues();
    }
    toggleOperatorInfo = () => {
        this.setState({
            modalOperatorInfo: !this.state.modalOperatorInfo,
        });
    };
    toggleEmailInfo = () => {
        this.setState({
            modalEmailInfo: !this.state.modalEmailInfo,
        });
    };
    toggleSMSInfo = () => {
        this.setState({
            modalSMSInfo: !this.state.modalSMSInfo,
        });
    };
    toggleDisplayOptionsInfo = () => {
        this.setState({
            modalDisplayOptionInfo: !this.state.modalDisplayOptionInfo,
        });
    };
    toggleTandCInfo = () => {
        this.setState({
            modalTandCInfo: !this.state.modalTandCInfo,
        });
    };
    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    handleEditorChange(emailMessage, editor) {
        this.setState({ emailMessage });
    }
    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    getVenues = (selectedID) => {
        this.setState({
            venueID: undefined,
            selectedUnit: undefined,
            selectedBuilding: undefined,
            selectedFloor: undefined,
            optionTemplateBuilding: [],
            optionTemplateFloor: [],
            optionTemplateUnits: [],
            unitID_internal: undefined,
            venueName: undefined,
        });
        this.setState({ attributeForm: [] });
        let body = { AccessToken: sessionStorage.getItem("AccessToken") };
        axios
            .post("/networkoperatorconsole/venues/listVenues", body, {
                headers: {},
            })
            .then((response) => {
                var formArrayVenues = [];
                let optionTemplateVenues = [];
                this.setState({ venueList: response.data.VenueList });
                Object.keys(response.data.VenueList).map((oneKey, i) => {
                    if (selectedID === undefined) {
                        this.setState({ firstID: oneKey });
                    }
                    if (selectedID === oneKey) {
                        optionTemplateVenues.push(
                            <MDBSelectOption selected value={oneKey}>
                                {response.data.VenueList[oneKey]["VenueName"]}
                            </MDBSelectOption>
                        );
                    } else {
                        optionTemplateVenues.push(
                            <MDBSelectOption value={oneKey}>
                                {response.data.VenueList[oneKey]["VenueName"]}
                            </MDBSelectOption>
                        );
                    }
                    return true;
                });
                formArrayVenues.push(
                    <MDBSelect
                        id="selectVenueSubscriberModal"
                        getValue={this.handleSelectChangeVenue}
                        className="form-control"
                    >
                        <MDBSelectInput
                            className="selectInput venueSelectedSubscriberModal"
                            selected="Choose your venue"
                        />
                        <MDBSelectOptions search>
                            {optionTemplateVenues}
                        </MDBSelectOptions>
                    </MDBSelect>
                );
                this.setState({
                    attributeForm: formArrayVenues,
                });
            })
            .catch((error) => {
                this.setState({ error: true });
            });
    };
    getVenueDetails = (index) => {
        this.setState({ loadingVenueDetails: true });
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: index,
            UserTimeZone: new Date().getTimezoneOffset(),
        };
        this.setState({
            unitData: undefined,
            expiringData: undefined,
            searchData: undefined,
            value: "",
            servicePlans: undefined,
            keyPoolList: undefined,
            venueAdminList: undefined,
            venueEquipList: undefined,
            venueLeasingAgentList: undefined,
            staffArrayAll: undefined,
            venueInfo: undefined,
        });
        axios
            .post("/networkoperatorconsole/venues/listVenueDetails", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    venueInfo: response.data[index],
                    keypoolID: Object.keys(response.data[index]["Keypools"])[0],
                    servicePlans: response.data[index]["AvailableServicePlans"],
                    propMgmtID:
                        response.data[index]["PropertyManagementGroup"][
                            "PropertyManagementGroupID"
                        ],
                    sharedSecret:
                        response.data[index]["PropertyManagementGroup"][
                            "SharedSecret"
                        ],
                    keyPoolList: response.data[index]["Keypools"],
                    venueAdminList: response.data[index]["VenueAdministrators"],
                    venueLeasingAgentList:
                        response.data[index]["LeasingAgents"],
                    venueEquipList: response.data[index]["CoreEquipmentStatus"],
                });
                this.getAdmins();
                this.setState({ loadingVenueDetails: false });
                this.setState({ unitsArrayAll: undefined });
                let unitsArrayAll = [];
                let unitsArraySub = [];
                let unitsArrayGuest = [];
                for (
                    var i = 0;
                    i <
                    Object.keys(
                        response.data[index][
                            "Units-Building-Floor-List-Subscribers"
                        ]
                    ).length;
                    i++
                ) {
                    let unitID = Object.keys(
                        response.data[index][
                            "Units-Building-Floor-List-Subscribers"
                        ]
                    )[i];
                    let building = Object.keys(
                        response.data[index][
                            "Units-Building-Floor-List-Subscribers"
                        ][unitID]
                    )[0];
                    let floor = Object.keys(
                        response.data[index][
                            "Units-Building-Floor-List-Subscribers"
                        ][unitID][building]
                    )[0];
                    unitsArraySub[i] = {
                        Purpose: "Subscriber",
                        UnitID: unitID,
                        Building: building,
                        Floor: floor,
                        vlan: response.data[index][
                            "Units-Building-Floor-List-Subscribers"
                        ][unitID][building][floor]["vlan"],
                        address1:
                            response.data[index][
                                "Units-Building-Floor-List-Subscribers"
                            ][unitID][building][floor]["AddressLine1"],
                        address2:
                            response.data[index][
                                "Units-Building-Floor-List-Subscribers"
                            ][unitID][building][floor]["AddressLine2"],
                        city: response.data[index][
                            "Units-Building-Floor-List-Subscribers"
                        ][unitID][building][floor]["City"],
                        state: response.data[index][
                            "Units-Building-Floor-List-Subscribers"
                        ][unitID][building][floor]["State"],
                        country:
                            response.data[index][
                                "Units-Building-Floor-List-Subscribers"
                            ][unitID][building][floor]["Country.ISO3166A3"],
                        postalcode:
                            response.data[index][
                                "Units-Building-Floor-List-Subscribers"
                            ][unitID][building][floor]["PostalCode"],
                        hasActiveSubscription:
                            response.data[index][
                                "Units-Building-Floor-List-Subscribers"
                            ][unitID][building][floor][
                                "hasActiveOrFutureSubscription"
                            ],
                        subscriptions:
                            response.data[index][
                                "Units-Building-Floor-List-Subscribers"
                            ][unitID][building][floor]["Subscriptions"],
                        unitID_internal:
                            response.data[index][
                                "Units-Building-Floor-List-Subscribers"
                            ][unitID][building][floor]["UnitID_internal"],
                    };
                }
                for (
                    var j = 0;
                    j <
                    Object.keys(
                        response.data[index][
                            "Units-Building-Floor-List-GuestNetworks"
                        ]
                    ).length;
                    j++
                ) {
                    let unitID = Object.keys(
                        response.data[index][
                            "Units-Building-Floor-List-GuestNetworks"
                        ]
                    )[j];
                    let building = Object.keys(
                        response.data[index][
                            "Units-Building-Floor-List-GuestNetworks"
                        ][unitID]
                    )[0];
                    let floor = Object.keys(
                        response.data[index][
                            "Units-Building-Floor-List-GuestNetworks"
                        ][unitID][building]
                    )[0];
                    unitsArrayGuest[i] = {
                        Purpose: "Guest",
                        UnitID: unitID,
                        Building: building,
                        Floor: floor,
                        vlan: response.data[index][
                            "Units-Building-Floor-List-GuestNetworks"
                        ][unitID][building][floor]["vlan"],
                        address1:
                            response.data[index][
                                "Units-Building-Floor-List-GuestNetworks"
                            ][unitID][building][floor]["AddressLine1"],
                        address2:
                            response.data[index][
                                "Units-Building-Floor-List-GuestNetworks"
                            ][unitID][building][floor]["AddressLine2"],
                        city: response.data[index][
                            "Units-Building-Floor-List-GuestNetworks"
                        ][unitID][building][floor]["City"],
                        state: response.data[index][
                            "Units-Building-Floor-List-GuestNetworks"
                        ][unitID][building][floor]["State"],
                        country:
                            response.data[index][
                                "Units-Building-Floor-List-GuestNetworks"
                            ][unitID][building][floor]["Country.ISO3166A3"],
                        postalcode:
                            response.data[index][
                                "Units-Building-Floor-List-GuestNetworks"
                            ][unitID][building][floor]["PostalCode"],
                        hasActiveSubscription:
                            response.data[index][
                                "Units-Building-Floor-List-GuestNetworks"
                            ][unitID][building][floor][
                                "hasActiveOrFutureSubscription"
                            ],
                        subscriptions:
                            response.data[index][
                                "Units-Building-Floor-List-GuestNetworks"
                            ][unitID][building][floor]["Subscriptions"],
                        unitID_internal:
                            response.data[index][
                                "Units-Building-Floor-List-GuestNetworks"
                            ][unitID][building][floor]["UnitID_internal"],
                    };
                }
                unitsArrayAll = unitsArraySub.concat(unitsArrayGuest);
                this.setState({ unitsArrayAll: [...unitsArrayAll] });
            })
            .catch((error) => {
                this.setState({ error: true });
                this.setState({ loadingVenueDetails: false });
            });
    };
    getAdmins = () => {
        this.setState({ staffArrayAll: undefined });
        let staffArrayAll = [];
        let staffArrayVA = [];
        let staffArrayLeasing = [];
        for (var i = 0; i < this.state.venueAdminList.length; i++) {
            staffArrayVA[i] = {
                [this.state.venueAdminList[i]]: "Venue Administrator",
            };
        }
        for (var j = 0; j < this.state.venueLeasingAgentList.length; j++) {
            staffArrayLeasing[j] = {
                [this.state.venueLeasingAgentList[j]]: "Leasing Agent",
            };
        }
        staffArrayAll = staffArrayVA.concat(staffArrayLeasing);
        this.setState({ staffArrayAll: [...staffArrayAll] });
    };
    handleSelectChangeVenue = (value) => {
        this.setState({
            optionTemplateUnits: [],
            optionTemplateBuilding: [],
            optionTemplateFloor: [],
        });
        var venue = document.getElementsByClassName(
            "venueSelectedSubscriberModal"
        )[0].value;
        var lDiv = document.createElement("div");
        document.body.appendChild(lDiv);
        lDiv.style.fontSize = "" + 20 + "px";
        lDiv.style.position = "absolute";
        lDiv.style.left = -1000;
        lDiv.style.top = -1000;
        lDiv.innerHTML = venue;
        document.body.removeChild(lDiv);
        lDiv = null;
        sessionStorage.setItem("venueName", venue);
        sessionStorage.setItem("venueID", value[0]);
        this.getVenueDetails(value[0]);
        this.setState({ selectedID: value[0] });
        this.updateName(venue, value[0]);
    };
    handleSelectChangeVenueFromList = (venueName, venueID) => {
        sessionStorage.setItem("venueName", venueName);
        sessionStorage.setItem("venueID", venueID);
        this.getVenues(venueID);
        this.setState({ selectedID: venueID });
        this.updateName(venueName, venueID);
    };
    updateName = (value, index) => {
        sessionStorage.setItem("venueName", value);
        sessionStorage.setItem("venueID", index);
        this.setState({ venueID: index });
        this.getVenueDetails(index);
    };
    render() {
        if (this.state.postMessage) {
            var h = document.getElementById("overlaySettings");
            h.style.display = "block";
            setTimeout(function () {
                h.style.display = "block";
            }, 5000);
            setTimeout(() => {
                h.style.display = "none";
                this.setState({ postMessage: "" });
            }, 6000);
        }
        return (
            <>
                {this.props.loading === true ? (
                    <div
                        className="overlay"
                        id="overlay"
                        style={{ display: "block" }}
                    >
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <MDBModalHeader toggle={this.props.toggleOperatorSettings}>
                    <h4>Operator Settings</h4>
                </MDBModalHeader>
                <MDBModalBody>
                    <div className="managementModal">
                        <>
                            <MDBRow>
                                <MDBCol size="12" md="6" lg="4">
                                    <div
                                        className="managementModalButton"
                                        onClick={this.toggleOperatorInfo}
                                    >
                                        <MDBIcon icon="landmark" />
                                        <p>Operator Information</p>
                                    </div>
                                </MDBCol>
                                <MDBCol size="12" md="6" lg="4">
                                    <div
                                        className="managementModalButton"
                                        onClick={this.toggleEmailInfo}
                                    >
                                        <MDBIcon icon="envelope-open" />
                                        <p>
                                            Email
                                            <br /> Templates
                                        </p>
                                    </div>
                                </MDBCol>
                                {/* <MDBCol md="4">
                  <div className="managementModalButton" onClick={this.toggleSMSInfo}>
                    <MDBIcon icon="sms" />
                    <p>
                      SMS
                      <br /> Templates
                    </p>
                  </div>
                </MDBCol> */}
                {/* <MDBCol md="4">
                  <div className="managementModalButton" onClick={this.toggleDisplayOptionsInfo}>
                                        <MDBIcon icon="desktop" />
                                        <p>
                                            Display
                                            <br /> Options
                                        </p>
                                    </div>
                </MDBCol> */}
                <MDBCol md="4">
                  <div className="managementModalButton" onClick={this.toggleTandCInfo}>
                                        <MDBIcon icon="exclamation-circle" />
                                        <p>Terms and Conditions</p>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </>
                    </div>
                </MDBModalBody>
                <MDBModal
                    isOpen={this.state.modalOperatorInfo}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <OperatorInfo
                        toggleOperatorInfo={this.toggleOperatorInfo}
                        venueID={this.state.venueID}
                        updateName={this.props.updateName}
                        venueName={sessionStorage.getItem("venueName")}
                        handleSelectChangeVenueFromList={
                            this.handleSelectChangeVenueFromList
                        }
                        getVenueDetails={this.getVenueDetails}
                        getVenues={this.getVenues}
                        attributeForm={this.state.attributeForm}
                        venueInfo={this.state.venueInfo}
                        unitsArrayAll={this.state.unitsArrayAll}
                        keyPoolList={this.state.keyPoolList}
                        servicePlans={this.state.servicePlans}
                        staffArrayAll={this.state.staffArrayAll}
                        venueEquipList={this.state.venueEquipList}
                        sharedSecret={this.state.sharedSecret}
                        formArrayVenues={this.state.formArrayVenues}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalEmailInfo}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <EmailInfo
                        toggleEmailInfo={this.toggleEmailInfo}
                        venueID={this.props.venueID}
                        updateName={this.props.updateName}
                        venueName={sessionStorage.getItem("venueName")}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalSMSInfo}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <SMSInfo
                        toggleSMSInfo={this.toggleSMSInfo}
                        venueID={this.props.venueID}
                        updateName={this.props.updateName}
                        venueName={sessionStorage.getItem("venueName")}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalDisplayOptionInfo}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <DisplayOptionsInfo
                        toggleDisplayOptionsInfo={this.toggleDisplayOptionsInfo}
                        venueID={this.props.venueID}
                        updateName={this.props.updateName}
                        venueName={sessionStorage.getItem("venueName")}
                        formArrayVenues={this.state.formArrayVenues}
                        displayOptionID={this.props.displayOptionID}
                    />
                </MDBModal>
                <MDBModal
                    isOpen={this.state.modalTandCInfo}
                    toggle={this.toggle}
                    size="fluid"
                    className="form"
                    centered
                >
                    <TandCInfo
                        toggleTandCInfo={this.toggleTandCInfo}
                        venueID={this.props.venueID}
                        updateName={this.props.updateName}
                        venueName={sessionStorage.getItem("venueName")}
                    />
                </MDBModal>
            </>
        );
    }
}
export default withTranslation()(PropertyManagementNavigation);
