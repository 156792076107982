import React, { Component } from "react";
import {
    MDBBtn,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter,
    MDBRow,
    MDBCol,
    MDBIcon,
    MDBModal,
    toast,
    MDBTabPane,
    MDBTabContent,
    MDBNav,
    MDBNavItem,
    MDBNavLink,
    MDBInput,
    MDBSpinner,
    MDBTooltip,
} from "mdbreact";
import axios from "../../../../axios";
import {
    CountryDropdown,
    RegionDropdown,
} from "vault-react-country-region-selector";
import { withTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import PhoneInput, {
    isPossiblePhoneNumber,
    isValidPhoneNumber,
} from "react-phone-number-input";
import $ from "jquery";
import ViewVenueServicePlans from "../../../../components/NetworkMgmt/Venues/ViewVenueServicePlans";
import ViewVenueKeypools from "../../../../components/NetworkMgmt/Venues/ViewVenueKeypools";
import ViewVenueAdministrators from "../../../../components/NetworkMgmt/Venues/ViewVenueAdministrators";
import ViewVenueEquipment from "../../../../components/NetworkMgmt/Venues/ViewVenueEquipment";
import ViewVenueUnits from "../../../../components/NetworkMgmt/Venues/ViewVenueUnits";
import ViewVenueBandwidthUsage from "../../../../components/NetworkMgmt/Venues/ViewVenueBandwidthUsage";
import SelectSearch, { fuzzySearch } from "react-select-search";
import TransactionsTable from "../../../../components/NetworkMgmt/Transactions/Datatables/VenueTransactionsTableMUI";
import { DateInput } from "semantic-ui-calendar-react";
import countryInfo from "country-locale-map";
import JSONPretty from "react-json-pretty";
class AddNewResident extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: null,
            content: null,
            loading_prop_groups: false,
            invalidCountry: null,
            invalidState: null,
            venueID: this.props.venueID,
            loading: false,
            loadingDelete: false,
            loadingMessages: true,
            ssid: this.props.SSID,
            activeItem: "1",
            loadingResend: false,
            loading_venue_info: false,
            loading_keypools: false,
            clearDropDown: this.props.clearDropDown,
            venueTypeOptions: [
                { value: "WIRED+WIFI", name: "Wi-Fi and Wired Access" },
                { value: "WIRED", name: "Wired Access Only" },
            ],
            enableSyncMessage: "",
            dateStart: undefined,
            dateEnd: undefined,
            trialCyclesOptions: [
                { value: -1, name: -1 },
                { value: 0, name: 0 },
                { value: 1, name: 1 },
            ],
            selectedID: this.props.selectedID,
            // optionTemplateVenues: this.props.optionTemplateVenues,
            disabled:
                sessionStorage.getItem("customerSupport") > 0 ? true : false,
        };
    }
    componentDidMount() {
        this.getKeyPools();
        this.getPropertyManagementGroups();
        this.getLogs();
        this.getVenues();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.venueInfo !== this.props.venueInfo) {
            this.setState({
                venueInfo: this.props.venueInfo,
                propMgmtID: undefined,
                clearDropDown: false,
            });
            this.getVenueInfo();
            this.getPropertyManagementGroups();
            this.getKeyPools();
            this.getLogs();
        }
    }
    getVenueInfo = () => {
        this.setState({ loading_venue_info: true });
        if (this.props.venueInfo !== undefined) {
            this.setState({
                venueName: this.props.venueInfo["VenueName"],
                address1: this.props.venueInfo["AddressLine1"],
                address2: this.props.venueInfo["AddressLine2"],
                city: this.props.venueInfo["City"],
                state: this.props.venueInfo["State"],
                postalcode: this.props.venueInfo["PostalCode"],
                country: this.props.venueInfo["Country.ISO3166A3"],
                contactName: this.props.venueInfo["PrimaryContactName"],
                firstName: this.props.venueInfo["PrimaryContactGivenName"],
                lastName: this.props.venueInfo["PrimaryContactFamilyName"],
                phoneNumber:
                    this.props.venueInfo["DisplayConfiguration"]["Support"][
                        "SupportPhoneNumber.E164"
                    ],
                phone: this.props.venueInfo["PrimaryContactPhoneNumber.E164"],
                email: this.props.venueInfo[
                    "SupportEmailAddress"
                ],
                region: this.props.venueInfo["State"],
                value: "en",
                countdown: this.props.venueInfo["WelcomeEmail_countdown_hours"],
                selectGroup:
                    this.props.venueInfo["PropertyManagementGroup"][
                        "PropertyManagementGroupID"
                    ],
                selectKeypool: Object.keys(this.props.venueInfo["Keypools"])[0],
                durationResident:
                    this.props.venueInfo[
                        "DefaultSubscriptionDurationHours_GlobalWirelessPlusLocalWiredAccess"
                    ],
                durationGuest:
                    this.props.venueInfo[
                        "DefaultSubscriptionDurationHours_LocalWirelessAccess"
                    ],
                selectDefaultServicePlanResident:
                    this.props.venueInfo[
                        "DefaultServicePlanID_GlobalWirelessPlusLocalWiredAccess"
                    ],
                selectDefaultServicePlanWireless:
                    this.props.venueInfo[
                        "DefaultServicePlanID_LocalWirelessAccess"
                    ],
                nasID: this.props.venueInfo["NAS-Identifier"],
                minVlanRes:
                    this.props.venueInfo[
                        "MinimumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess"
                    ],
                maxVlanRes:
                    this.props.venueInfo[
                        "MaximumAutoGeneratedVLAN_GlobalWirelessPlusLocalWiredAccess"
                    ],
                minVlanGuest:
                    this.props.venueInfo[
                        "MinimumAutoGeneratedVLAN_LocalWirelessAccess"
                    ],
                maxVlanGuest:
                    this.props.venueInfo[
                        "MaximumAutoGeneratedVLAN_LocalWirelessAccess"
                    ],
                minVlanRoaming:
                    this.props.venueInfo[
                        "MinimumAutoGeneratedVLAN_GlobalWirelessRoaming"
                    ],
                maxVlanRoaming:
                    this.props.venueInfo[
                        "MaximumAutoGeneratedVLAN_GlobalWirelessRoaming"
                    ],
                realpageSiteID: this.props.venueInfo["RealPageSiteId"],
                realpagePMCID: this.props.venueInfo["RealPagePMCId"],
                realpageSyncDate:
                    this.props.venueInfo["RealPageSyncDateTimeUTC.ISO8601"] !==
                    ""
                        ? Intl.DateTimeFormat("en-US", {
                              year: "numeric",
                              month: "numeric",
                              day: "numeric",
                              hour: "numeric",
                              minute: "numeric",
                          }).format(
                              new Date(
                                  this.props.venueInfo[
                                      "RealPageSyncDateTimeUTC.ISO8601"
                                  ]
                              )
                          )
                        : "",
                selectedVenueType: this.props.venueInfo["VenueType"],
                shoppingCartPriceAdjustment:
                    this.props.venueInfo["ShoppingCart"][
                        "ShoppingCartPriceAdjustment." +
                            countryInfo.getCurrencyByAlpha3(
                                this.props.venueInfo["Country.ISO3166A3"]
                            )
                    ] / 100,
                enableRealPageSync: this.props.venueInfo["enableRealPageSync"],
                loading_venue_info: false,
                enableShoppingCart:
                    this.props.venueInfo["ShoppingCart"]["enableShoppingCart"],
                sendSubscriptionAddedEmail:
                    this.props.venueInfo["sendSubscriptionAddedEmail"],
                sendSubscriptionEndEmail:
                    this.props.venueInfo["sendSubscriptionEndEmail"],
                allowOverlapping:
                    this.props.venueInfo[
                        "allowOverlappingSubscriptionWiredUnit"
                    ],
                selectedTrialCycles:
                    this.props.venueInfo["ShoppingCart"][
                        "TrialCycleAdjustment"
                    ],
                selectedID: this.props.venueInfo["VenueID"],
                dateAdded: this.props.venueInfo["dateAddedUTC.ISO8601"],
                externalVenueID: this.props.venueInfo["externalVenueID"],
                stripePublishableKey:
                    this.props.venueInfo["ShoppingCart"][
                        "StripePublishableKey"
                    ],
                stripeSecretKey:
                    this.props.venueInfo["ShoppingCart"]["Stripe_Secret_key"],
                stripeWebhookSigningSecret:
                    this.props.venueInfo["ShoppingCart"][
                        "Stripe_Webhook_signingsecret"
                    ],
                displayFCCLabel:
                    this.props.venueInfo["FCCLabel"]["displayLabel"],
                typicalLatency:
                    this.props.venueInfo["FCCLabel"]["TypicalLatency"] === -1
                        ? ""
                        : this.props.venueInfo["FCCLabel"]["TypicalLatency"],
                displayOptionIDGlobal: this.props.displayOptionIDGlobal,
                displayOptionID: this.props.venueInfo["DisplayOptionID"],
            });
            let optionTemplateServicePlans = [];
            Object.keys(this.props.venueInfo["AvailableServicePlans"]).map(
                (value, i) => {
                    optionTemplateServicePlans.push({
                        value: this.props.venueInfo["AvailableServicePlans"][
                            value
                        ]["ServicePlanID"],
                        name: this.props.venueInfo["AvailableServicePlans"][
                            value
                        ]["ServicePlanName"],
                    });
                    return true;
                }
            );
            this.setState({
                optionTemplateServicePlans: optionTemplateServicePlans,
                loading: false,
            });
        }
    };
    validateEmail = (email) => {
        var mailformat = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
        this.setState({
            invalidPhone: undefined,
            userFound: false,
            userFoundWithPhone: false,
        });
        if (!this.state.email.match(mailformat)) {
            this.setState({
                invalidEmail: "Please provide a valid email address",
            });
            $(".emailInput").addClass("is-invalid");
        } else {
            this.setState({ invalidEmail: undefined });
            $(".emailInput").removeClass("is-invalid");
        }
    };
    handleSelectGroup = (value) => {
        this.setState({ selectGroup: value });
    };
    handleSelectVenueType = (value) => {
        this.setState({ selectedVenueType: value });
    };
    handleSelectTrialCycles = (value) => {
        this.setState({ selectedTrialCycles: value });
    };
    modifyVenueInfo = (event) => {
        event.preventDefault();
        event.target.className += " was-validated";
        // $("#selectGroup").removeClass("is-invalid");
        // if (this.state.selectGroup === undefined) {
        //   this.setState({
        //     invalidLanguage: "Please provide a valid group name",
        //   });
        //   $("#selectGroup").addClass("is-invalid");
        // } else {
        //   $("#selectGroup").addClass("is-valid");
        // }
        // $("#selectVenueType").removeClass("is-invalid");
        // if (this.state.selectedVenueType === undefined) {
        //   this.setState({
        //     invalidLanguage: "Please provide a valid Venue Type",
        //   });
        //   $("#selectVenueType").addClass("is-invalid");
        // } else {
        //   $("#selectVenueType").addClass("is-valid");
        // }
        // if (this.state.email === null || this.state.email === undefined) {
        //     this.setState({
        //         invalidEmail: "Please provide a valid email address",
        //     });
        // }
        // if (
        //     this.state.phoneNumber === null ||
        //     this.state.phoneNumber === undefined
        // ) {
        //     this.setState({
        //         invalidPhone: "Please provide a valid phone number",
        //     });
        //     $(".PhoneInput").addClass("is-invalid");
        // } else {
        //     $(".PhoneInput").addClass("is-valid");
        // }
        if (
            this.state.venueName !== undefined &&
            this.state.address1 !== undefined &&
            this.state.country !== undefined &&
            this.state.city !== undefined &&
            this.state.region !== undefined &&
            this.state.postalcode !== undefined
            // this.state.email !== undefined &&
            // this.state.phoneNumber !== undefined
        ) {
            this.props.modifyVenueInfo(
                this.state.address1,
                this.state.address2,
                this.state.phoneNumber,
                this.state.country,
                this.state.city,
                this.state.region,
                this.state.postalcode,
                this.state.email,
                this.state.countdown,
                this.state.durationResident,
                this.state.durationGuest,
                this.state.firstName,
                this.state.lastName,
                this.state.minVlanRes,
                this.state.maxVlanRes,
                this.state.minVlanGuest,
                this.state.maxVlanGuest,
                this.state.minVlanRoaming,
                this.state.maxVlanRoaming,
                this.state.realpageSiteID,
                this.state.realpagePMCID,
                this.state.realpageSyncDate,
                this.state.selectedVenueType,
                this.state.shoppingCartPriceAdjustment,
                this.state.enableRealPageSync,
                this.state.enableShoppingCart,
                this.state.sendSubscriptionAddedEmail,
                this.state.sendSubscriptionEndEmail,
                this.state.allowOverlapping,
                this.state.selectedTrialCycles,
                this.state.stripePublishableKey,
                this.state.stripeSecretKey,
                this.state.stripeWebhookSigningSecret,
                this.state.venueName,
                this.state.displayFCCLabel,
                this.state.typicalLatency,
                this.state.displayOptionID
            );
        }
    };
    changeHandler = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };
    changeHandlerPhone = (event) => {
        this.setState({ phoneNumber: event });
        var value = event
            ? isPossiblePhoneNumber(event)
                ? undefined
                : "Please provide a valid phone number"
            : "Phone number required";
        this.setState({ invalidPhone: value });
    };
    toggleCheckboxValueEnable = () => {
        this.setState({
            enableRealPageSync: !this.state.enableRealPageSync,
            enableSyncMessage:
                "Note: Enabling this setting (1) transfers subscribers with known email address to realpage enabled units. (2) Deletes empty units that were added directly to VAULT - you may be billed for non-empty units that were added directly to VAULT.",
        });
    };
    toggleCheckboxValueEnableShoppingCart = () => {
        this.setState({
            enableShoppingCart: !this.state.enableShoppingCart,
        });
    };
    toggleCheckboxValuesendSubscriptionAddedEmail = () => {
        this.setState({
            sendSubscriptionAddedEmail: !this.state.sendSubscriptionAddedEmail,
        });
    };
    toggleCheckboxValuesendSubscriptionEndedEmail = () => {
        this.setState({
            sendSubscriptionEndEmail: !this.state.sendSubscriptionEndEmail,
        });
    };
    toggleCheckboxValueAllowOverlapping = () => {
        this.setState({ allowOverlapping: !this.state.allowOverlapping });
    };
    toggleCheckboxValueDisplayFCCLabel = () => {
        this.setState({
            displayFCCLabel: !this.state.displayFCCLabel,
        });
    };
    createMask = () => {
        this.setState({
            phoneNumber: this.state.phoneNumber.replace(/\D/g, ""),
        });
    };
    handleChange = (event, { name, value }) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    };
    toggleConfirmDisablePlan = (key) => {
        this.setState({
            modalConfirmDisablePlan: !this.state.modalConfirmDisablePlan,
        });
    };
    submitDelete = (event) => {
        this.props.submitDelete(
            this.state.email,
            this.state.venueID,
            this.state.unitID,
            this.state.building,
            this.state.floor,
            this.state.recentResidents,
            this.state.oldDateStart
        );
    };
    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            modalMessage: !this.state.modalMessage,
        });
    };
    toggleTab = (tab) => () => {
        if (this.state.activeItem !== tab) {
            this.setState({
                activeItem: tab,
            });
        }
    };
    disablePlanConfirm = (key) => {
        this.toggleConfirmDisablePlan();
        this.setState({
            toggleConfirmDisablePlan: true,
            modal: !this.state.modal,
            servicePlanID: this.props.servicePlans[key]["ServicePlanID"],
            servicePlanName: this.props.servicePlans[key]["ServicePlanName"],
        });
    };
    disablePlan = () => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            SharedSecret: this.props.sharedSecret,
            VenueID: this.props.venueID,
            ServicePlanID: this.state.servicePlanID,
        };
        this.setState({ disable_serviceplan: true });
        axios
            .post("/networkoperatorconsole/venues/disableServicePlan", body, {
                headers: {},
            })
            .then(
                (response) => {
                    toast.success("Service Plan Disabled!", {
                        closeButton: false,
                    });
                    this.props.getVenueDetails(this.props.venueID);
                    this.toggleConfirmDisablePlan();
                    this.setState({ disable_serviceplan: false });
                },
                (error) => {
                    toast.error("There was an error", {
                        closeButton: false,
                    });
                    this.toggleConfirmDisablePlan();
                    this.setState({ disable_serviceplan: false });
                }
            );
    };
    getPropertyManagementGroups = async () => {
        this.setState({
            loading_prop_groups: true,
            groupForm: undefined,
        });
        const data = {
            AccessToken: sessionStorage.getItem("AccessToken"),
        };
        await axios
            .post(
                "/networkoperatorconsole/venues/listPropertyManagementGroups",
                data,
                {
                    headers: {},
                }
            )
            .then((response) => {
                let optionTemplateGroup = [];
                Object.keys(response.data.PropertyManagementGroups).map(
                    (oneKey, i) => {
                        optionTemplateGroup.push({
                            value: oneKey,
                            name: response.data.PropertyManagementGroups[
                                oneKey
                            ]["PropertyManagementGroupName"],
                        });
                        return true;
                    }
                );
                this.setState({
                    optionTemplateGroup: optionTemplateGroup,
                    loading_prop_groups: false,
                });
                this.setState({ loading_prop_groups: false });
            })
            .catch((error) => {
                this.setState({ loading_prop_groups: false });
            });
    };
    getKeyPools = async () => {
        this.setState({
            loading_keypools: true,
            keypoolForm: undefined,
        });
        const data = {
            AccessToken: sessionStorage.getItem("AccessToken"),
        };
        await axios
            .post("/networkoperatorconsole/keypools/listKeypools", data, {
                headers: {},
            })
            .then((response) => {
                let optionTemplateKeyPools = [];
                Object.keys(response.data.myKeyPoolList).map((oneKey, i) => {
                    optionTemplateKeyPools.push({
                        value: oneKey,
                        name: response.data.myKeyPoolList[oneKey]["ssid"],
                    });
                    return true;
                });
                this.setState({
                    optionTemplateKeyPools: optionTemplateKeyPools,
                    loading_prop_groups: false,
                    keypoolList: response.data.myKeyPoolList
                });
                this.setState({ loading_keypools: false });
            })
            .catch((error) => {
                this.setState({ loading_keypools: false });
            });
    };
    getVenues = () => {
        let body = { AccessToken: sessionStorage.getItem("AccessToken") };
        axios
            .post("/networkoperatorconsole/venues/listVenues", body, {
                headers: {},
            })
            .then((response) => {
                let optionTemplateVenues = [];
                optionTemplateVenues.push({
                    value: "",
                    name: "Please Select...",
                });
                Object.keys(response.data.VenueList).map((oneKey, i) => {
                    optionTemplateVenues.push({
                        value: oneKey,
                        name: response.data.VenueList[oneKey]["VenueName"],
                    });
                    return true;
                });
                this.setState({
                    optionTemplateVenues: optionTemplateVenues,
                    loading_prop_groups: false,
                });
            })
            .catch((error) => {
                this.setState({ error: true });
            });
    };
    handleSelectVenue = (value, name) => {
        this.setState({
            selectVenue: value,
            selectedID: value,
        });
        this.props.updateName(name.name, name.value);
    };
    getLogs = async () => {
        let body = {
            AccessToken: sessionStorage.getItem("AccessToken"),
            VenueID: sessionStorage.getItem("venueID"),
        };
        await axios
            .post("/networkoperatorconsole/venues/listVenueLog", body, {
                headers: {},
            })
            .then((response) => {
                this.setState({
                    venueLog: response.data.log,
                });
            })
            .catch((error) => {
                this.setState({ error: true });
            });
    };
    changeHandlerRadio = (nr) => () => {
        this.setState({
            enableRealPageSync: nr,
        });
    };
    findTransactions = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        if (
            this.state.dateStart !== undefined &&
            this.state.dateEnd !== undefined
        ) {
            let body = {
                AccessToken: sessionStorage.getItem("AccessToken"),
                "MinInvoiceDateTimeUTC.ISO8601": new Date(
                    this.state.dateStart
                ).toISOString(),
                "MaxinvoiceDateTimeUTC.ISO8601": new Date(
                    this.state.dateEnd
                ).toISOString(),
                VenueID: this.props.venueID,
            };
            axios
                .post(
                    "/networkoperatorconsole/shoppingcart/listPayments",
                    body,
                    {
                        headers: {},
                    }
                )
                .then(
                    (response) => {
                        this.setState({ transactions: response.data.Payments });
                        this.setState({ loading: false });
                    },
                    (error) => {
                        this.setState({ loading: false });
                    }
                );
        }
    };
    selectCountry(val) {
        this.setState({ country: val });
    }
    selectRegion(val) {
        this.setState({ region: val });
    }
    copyNASID = () => {
        var copyText = document.getElementById("passwordHidden");
        var textArea = document.createElement("textarea");
        textArea.value = copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        this.setState({ copyText: "Copied" });
        textArea.remove();
        toast.success("NAS Identifier copied", {
            closeButton: false,
        });
    };
    copyVenueID = () => {
        var copyText = document.getElementById("venueIDHidden");
        var textArea = document.createElement("textarea");
        textArea.value = copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        this.setState({ copyText: "Copied" });
        textArea.remove();
        toast.success("Venue ID copied", {
            closeButton: false,
        });
    };
    copyExternalVenueID = () => {
        var copyText = document.getElementById("externalVenueIDHidden");
        var textArea = document.createElement("textarea");
        textArea.value = copyText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("Copy");
        this.setState({ copyText: "Copied" });
        textArea.remove();
        toast.success("External Venue ID copied", {
            closeButton: false,
        });
    };
    unlockStripeKeys = () => {
        var stripePublishableKey = document.getElementById(
            "stripePublishableKey"
        );
        var stripeSecretKey = document.getElementById("stripeSecretKey");
        var stripeWebhookSigningSecret = document.getElementById(
            "stripeWebhookSigningSecret"
        );
        var unlockStripeKeys = document.getElementById("unlockStripeKeys");
        stripePublishableKey.disabled = !stripePublishableKey.disabled;
        stripeSecretKey.disabled = !stripeSecretKey.disabled;
        stripeWebhookSigningSecret.disabled =
            !stripeWebhookSigningSecret.disabled;
        if (unlockStripeKeys.className === "fa fa-lock") {
            unlockStripeKeys.className = "fa fa-unlock";
        } else {
            unlockStripeKeys.className = "fa fa-lock";
        }
    };

    changeHandlerDisplayOptionID = (value) => {
        this.setState({ displayOptionID: value });
    };
    render() {
        const { country, region } = this.state;
        let tomorrow = new Date(
            new Date().setDate(new Date().getDate() + 1)
        ).toISOString();
        const { t } = this.props;
        const FORMAT = "MM/dd/yyyy";
        return (
            <>
                {this.state.toggleConfirm ? (
                    <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                        <MDBModalHeader toggle={this.toggle}>
                            Confirm Deletion
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>
                                Name:{" "}
                                {this.state.firstName +
                                    " " +
                                    this.state.lastName}
                            </p>
                            <p>Email: {this.state.email}</p>
                            <p>Unit: {this.state.unitID}</p>
                            <p>Building: {this.state.building}</p>
                            <p>Floor: {this.state.floor}</p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color="primary" onClick={this.submitDelete}>
                                Confirm
                            </MDBBtn>
                            <MDBBtn color="primary" onClick={this.toggle}>
                                Close
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModal>
                ) : null}
                <MDBModal
                    isOpen={this.state.modalConfirmDisablePlan}
                    toggle={this.toggleConfirmDisablePlan}
                >
                    <MDBModalHeader toggle={this.toggleConfirmDisablePlan}>
                        Confirm Service Plan Disable
                    </MDBModalHeader>
                    <MDBModalBody>
                        <p>Service Plan Name: {this.state.servicePlanName}</p>
                    </MDBModalBody>
                    <MDBModalFooter>
                        <MDBBtn color="primary" onClick={this.disablePlan}>
                            Confirm
                        </MDBBtn>
                        <MDBBtn
                            color="primary"
                            onClick={this.toggleConfirmDisablePlan}
                        >
                            Close
                        </MDBBtn>
                    </MDBModalFooter>
                </MDBModal>
                <MDBModalHeader toggle={this.props.toggleModifyVenue}>
                    <h4>Venue Details</h4>
                    {this.state.activeItem === "1" ? (
                        <p>{t("addnewresident.required")}</p>
                    ) : (
                        ""
                    )}
                </MDBModalHeader>
                <MDBModalBody className="venueDetails">
                    <label
                        htmlFor="defaultFormLoginEmailEx"
                        className="black-text"
                    >
                        Venue Name
                    </label>
                    <SelectSearch
                        id="selectVenue"
                        search={true}
                        filterOptions={fuzzySearch}
                        options={this.state.optionTemplateVenues}
                        isOptionSelected={true}
                        inputId="venueArray"
                        classNamePrefix="venue-select"
                        placeholder="Select..."
                        onChange={this.handleSelectVenue}
                        value={
                            this.state.clearDropDown === true
                                ? ""
                                : sessionStorage.getItem("venueID")
                        }
                    />
                    <MDBNav className="nav-tabs">
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "1"}
                                onClick={this.toggleTab("1")}
                                role="tab"
                            >
                                <MDBIcon icon="user" /> Profile
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "2"}
                                onClick={this.toggleTab("2")}
                                role="tab"
                                disabled={
                                    this.state.selectedID === undefined
                                        ? true
                                        : false
                                }
                            >
                                <MDBIcon icon="building" /> Units
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "3"}
                                onClick={this.toggleTab("3")}
                                role="tab"
                                disabled={
                                    this.state.selectedID === undefined
                                        ? true
                                        : false
                                }
                            >
                                <MDBIcon icon="chart-line" /> Bandwidth
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "4"}
                                onClick={this.toggleTab("4")}
                                role="tab"
                                disabled={
                                    this.state.selectedID === undefined
                                        ? true
                                        : false
                                }
                            >
                                <MDBIcon icon="signal" /> Service Plans
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "5"}
                                onClick={this.toggleTab("5")}
                                role="tab"
                                disabled={
                                    this.state.selectedID === undefined
                                        ? true
                                        : false
                                }
                            >
                                <MDBIcon icon="key" /> Keypools
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "6"}
                                onClick={this.toggleTab("6")}
                                role="tab"
                                disabled={
                                    this.state.selectedID === undefined
                                        ? true
                                        : false
                                }
                            >
                                <MDBIcon icon="user-shield" /> Administrators
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "7"}
                                onClick={this.toggleTab("7")}
                                role="tab"
                                disabled={
                                    this.state.selectedID === undefined
                                        ? true
                                        : false
                                }
                            >
                                <MDBIcon icon="server" /> Equipment
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "8"}
                                onClick={this.toggleTab("8")}
                                role="tab"
                                disabled={
                                    this.state.selectedID === undefined
                                        ? true
                                        : false
                                }
                            >
                                <MDBIcon icon="clipboard-list" /> Logs
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "9"}
                                onClick={this.toggleTab("9")}
                                role="tab"
                                disabled={
                                    this.state.selectedID === undefined
                                        ? true
                                        : false
                                }
                            >
                                <MDBIcon icon="shopping-cart" /> Shopping Cart
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "10"}
                                onClick={this.toggleTab("10")}
                                role="tab"
                                disabled={
                                    this.state.selectedID === undefined
                                        ? true
                                        : false
                                }
                            >
                                <MDBIcon icon="home" /> RealPage
                            </MDBNavLink>
                        </MDBNavItem>
                        <MDBNavItem>
                            <MDBNavLink
                                link
                                to="#"
                                active={this.state.activeItem === "11"}
                                onClick={this.toggleTab("11")}
                                role="tab"
                                disabled={
                                    this.state.selectedID === undefined
                                        ? true
                                        : false
                                }
                            >
                                <MDBIcon icon="file-invoice-dollar" />{" "}
                                Transactions
                            </MDBNavLink>
                        </MDBNavItem>
                        {/* <MDBNavItem>
							<MDBNavLink
								link
								to="#"
								active={this.state.activeItem === "12"}
								onClick={this.toggleTab("12")}
								role="tab"
								disabled={this.state.selectedID === undefined ? true : false}
							>
								<MDBIcon icon="database"/> System Data
							</MDBNavLink>
						</MDBNavItem> */}
                    </MDBNav>
                    <MDBTabContent
                        className="mt-1"
                        activeItem={this.state.activeItem}
                    >
                        <MDBTabPane tabId="1" role="tabpanel">
                            {
                                // this.props.attributeForm.length === 0 ||
                                this.state.loading_venue_info === true ||
                                this.state.loading_keypools === true ||
                                this.state.loading_prop_groups === true ? (
                                    <div
                                        className="overlay"
                                        id="overlay"
                                        style={{ display: "block" }}
                                    >
                                        <div className="overlayMessage">
                                            <div
                                                className="spinner-border spinner-border"
                                                role="status"
                                            >
                                                <span className="sr-only">
                                                    Loading...
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <form
                                            className="needs-validation"
                                            onSubmit={this.modifyVenueInfo}
                                            noValidate
                                        >
                                            <h5 className="font-weight-bold pl-0 mt-1 mb-1">
                                                <strong>
                                                    General Information
                                                </strong>
                                            </h5>
                                            <MDBRow>
                                                <MDBCol col="6" sm="12" lg="6">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Venue Name*
                                                    </label>
                                                    <input
                                                        required
                                                        name="venueName"
                                                        id="venueName"
                                                        className="form-control"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        value={
                                                            this.state.venueName
                                                        }
                                                        disabled={
                                                            this.state.disabled
                                                        }
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please provide a valid
                                                        name
                                                    </div>
                                                </MDBCol>
                                                <MDBCol col="6" sm="12" lg="6">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Venue Type*
                                                    </label>
                                                    <MDBTooltip
                                                        material
                                                        placement="left"
                                                        className="rightTooltip"
                                                        style={{
                                                            display: "block",
                                                        }}
                                                    >
                                                        <MDBIcon
                                                            far
                                                            icon="question-circle"
                                                        />
                                                        <span>
                                                            - Wi-Fi and Wired
                                                            Access – Select if
                                                            users have Wi-Fi
                                                            access as well as
                                                            wired access
                                                            <br />- Wired Only
                                                            Access – Select if
                                                            there is no Managed
                                                            Wi-Fi access. E.g.
                                                            Bring your own
                                                            Device/CPE’
                                                        </span>
                                                    </MDBTooltip>
                                                    <SelectSearch
                                                        id="selectVenueType"
                                                        search={true}
                                                        filterOptions={
                                                            fuzzySearch
                                                        }
                                                        options={
                                                            this.state
                                                                .venueTypeOptions
                                                        }
                                                        inputId="venueTypeArray"
                                                        classNamePrefix="unit-select form-control"
                                                        placeholder="Select..."
                                                        onChange={
                                                            this
                                                                .handleSelectVenueType
                                                        }
                                                        value={
                                                            this.state
                                                                .selectedVenueType
                                                        }
                                                        disabled={
                                                            this.state.disabled
                                                        }
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please provide a Venue
                                                        Type
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol col="6" sm="12" lg="6">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Property Management
                                                        Group*
                                                    </label>
                                                    <SelectSearch
                                                        id="selectGroup"
                                                        search={true}
                                                        filterOptions={
                                                            fuzzySearch
                                                        }
                                                        options={
                                                            this.state
                                                                .optionTemplateGroup
                                                        }
                                                        isOptionSelected={true}
                                                        inputId="groupArray"
                                                        classNamePrefix="group-select"
                                                        placeholder="Select..."
                                                        onChange={
                                                            this
                                                                .handleSelectGroup
                                                        }
                                                        value={
                                                            this.state
                                                                .selectGroup
                                                        }
                                                        disabled={
                                                            this.state.disabled
                                                        }
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please provide a
                                                        Property Management
                                                        Group
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol sm="12" lg="6">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Address Line 1*
                                                    </label>
                                                    <input
                                                        required
                                                        name="address1"
                                                        id="address1"
                                                        className="form-control"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        value={
                                                            this.state.address1
                                                        }
                                                        disabled={
                                                            this.state.disabled
                                                        }
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please provide a valid
                                                        address
                                                    </div>
                                                </MDBCol>
                                                <MDBCol sm="12" lg="6">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Address Line 2
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        name="address2"
                                                        id="address2"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        value={
                                                            this.state.address2
                                                        }
                                                        disabled={
                                                            this.state.disabled
                                                        }
                                                    />
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol sm="12" lg="12">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Country*
                                                    </label>
                                                    <div className="select-box">
                                                        <CountryDropdown
                                                            value={country}
                                                            onChange={(val) =>
                                                                this.selectCountry(
                                                                    val
                                                                )
                                                            }
                                                            priorityOptions={[
                                                                "CAN",
                                                                "USA",
                                                                "GBR",
                                                            ]}
                                                            valueType="short"
                                                            countryvaluetype="short"
                                                            className="form-control"
                                                            id="selectCountry"
                                                            disabled
                                                            // disabled={this.state.disabled}
                                                        />
                                                    </div>
                                                    {this.state
                                                        .invalidCountry !==
                                                    null ? (
                                                        <div className="invalid">
                                                            {
                                                                this.state
                                                                    .invalidCountry
                                                            }
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol sm="12" lg="4">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        City*
                                                    </label>
                                                    <input
                                                        // required
                                                        name="city"
                                                        id="city"
                                                        className="form-control"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        value={this.state.city}
                                                        disabled
                                                        // disabled={this.state.disabled}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please provide a valid
                                                        city
                                                    </div>
                                                </MDBCol>
                                                <MDBCol sm="12" lg="4">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        State*
                                                    </label>
                                                    <div className="select-box">
                                                        <RegionDropdown
                                                            country={country}
                                                            value={region}
                                                            onChange={(val) =>
                                                                this.selectRegion(
                                                                    val
                                                                )
                                                            }
                                                            valueType="short"
                                                            className="form-control"
                                                            id="selectState"
                                                            disabled
                                                            // disabled={this.state.disabled}
                                                        />
                                                    </div>
                                                    {this.state.invalidState !==
                                                    null ? (
                                                        <div className="invalid">
                                                            {
                                                                this.state
                                                                    .invalidState
                                                            }
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </MDBCol>
                                                <MDBCol sm="12" lg="4">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Zip Code*
                                                    </label>
                                                    <input
                                                        // required
                                                        name="postalcode"
                                                        id="postalcode"
                                                        className="form-control"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        value={
                                                            this.state
                                                                .postalcode
                                                        }
                                                        disabled
                                                        // disabled={this.state.disabled}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please provide a valid
                                                        zipcode
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol col="6">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Primary Contact First
                                                        Name
                                                    </label>
                                                    <input
                                                        // required
                                                        name="firstName"
                                                        id="firstName"
                                                        className="form-control"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        value={
                                                            this.state.firstName
                                                        }
                                                        disabled={
                                                            this.state.disabled
                                                        }
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please provide a valid
                                                        first name
                                                    </div>
                                                </MDBCol>
                                                <MDBCol col="6">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Primary Contact Last
                                                        Name
                                                    </label>
                                                    <input
                                                        // required
                                                        name="lastName"
                                                        id="lastName"
                                                        className="form-control"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        value={
                                                            this.state.lastName
                                                        }
                                                        disabled={
                                                            this.state.disabled
                                                        }
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please provide a valid
                                                        last name
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol col="6" sm="12" lg="6">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Property Support Phone #
                                                    </label>
                                                    <MDBTooltip
                                                        material
                                                        placement="left"
                                                        className="rightTooltip"
                                                        style={{
                                                            display: "block",
                                                        }}
                                                    >
                                                        <MDBIcon
                                                            far
                                                            icon="question-circle"
                                                        />
                                                        <span>
                                                            The phone number
                                                            subscribers will see
                                                            on support pages.
                                                        </span>
                                                    </MDBTooltip>
                                                    <PhoneInput
                                                        placeholder={t(
                                                            "addnewresident.enterphone"
                                                        )}
                                                        value={
                                                            this.state
                                                                .phoneNumber
                                                        }
                                                        format={FORMAT}
                                                        onChange={
                                                            this
                                                                .changeHandlerPhone
                                                        }
                                                        name="phoneNumber"
                                                        id="phoneNumber"
                                                        className={
                                                            this.state
                                                                .loading ===
                                                            true
                                                                ? "form-control loading"
                                                                : "form-control"
                                                        }
                                                        defaultCountry="US"
                                                        required
                                                        error={
                                                            this.state
                                                                .phoneNumber
                                                                ? isValidPhoneNumber(
                                                                      this.state
                                                                          .phoneNumber
                                                                  )
                                                                    ? undefined
                                                                    : "Invalid phone number"
                                                                : "Phone number required"
                                                        }
                                                        disabled={
                                                            this.state
                                                                .userFoundWithPhone ||
                                                            this.state.disabled
                                                        }
                                                    />
                                                    {this.state.invalidPhone !==
                                                    null ? (
                                                        <div className="invalid">
                                                            {
                                                                this.state
                                                                    .invalidPhone
                                                            }
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </MDBCol>
                                                <MDBCol sm="12" lg="6">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Property Support E-mail
                                                    </label>
                                                    <MDBTooltip
                                                        material
                                                        placement="left"
                                                        className="rightTooltip"
                                                        style={{
                                                            display: "block",
                                                        }}
                                                    >
                                                        <MDBIcon
                                                            far
                                                            icon="question-circle"
                                                        />
                                                        <span>
                                                            The e-mail address
                                                            subscribers will see
                                                            on support pages.
                                                        </span>
                                                    </MDBTooltip>
                                                    <input
                                                        required
                                                        name="email"
                                                        id="email"
                                                        className="form-control emailInput"
                                                        onBlur={(event) =>
                                                            this.validateEmail(
                                                                this.state.email
                                                            )
                                                        }
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        value={this.state.email}
                                                        disabled={
                                                            this.state.disabled
                                                        }
                                                    />
                                                    {this.state.invalidEmail !==
                                                    undefined ? (
                                                        <div className="invalid">
                                                            {
                                                                this.state
                                                                    .invalidEmail
                                                            }
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </MDBCol>
                                            </MDBRow>
                                            <h5 className="font-weight-bold pl-0 mt-2 mb-1">
                                                <strong>
                                                    Configuration Options
                                                </strong>
                                            </h5>
                                            <MDBRow>
                                                <MDBCol
                                                    size="12"
                                                    md="12"
                                                    lg="6"
                                                >
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Welcome E-mail Send
                                                        Advance
                                                    </label>
                                                    <MDBTooltip
                                                        material
                                                        placement="left"
                                                        className="rightTooltip"
                                                        style={{
                                                            display: "block",
                                                        }}
                                                    >
                                                        <MDBIcon
                                                            far
                                                            icon="question-circle"
                                                        />
                                                        <span>
                                                            Enter the number of
                                                            hours prior to a
                                                            subscriber’s service
                                                            start time that you
                                                            want them to receive
                                                            their welcome
                                                            e-mail. Enter 0 if
                                                            you want the e-mails
                                                            to be sent as soon
                                                            as the subscriber is
                                                            added
                                                        </span>
                                                    </MDBTooltip>
                                                    <input
                                                        required
                                                        name="countdown"
                                                        id="countdown"
                                                        className="form-control"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        value={
                                                            this.state.countdown
                                                        }
                                                        disabled={
                                                            this.state.disabled
                                                        }
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please provide a valid
                                                        number
                                                    </div>
                                                </MDBCol>
                                                <MDBCol
                                                    size="12"
                                                    md="12"
                                                    lg="6"
                                                >
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Resident Devices VLAN
                                                        Range *
                                                    </label>
                                                    <MDBTooltip
                                                        material
                                                        placement="left"
                                                        className="rightTooltip"
                                                        style={{
                                                            display: "block",
                                                        }}
                                                    >
                                                        <MDBIcon
                                                            far
                                                            icon="question-circle"
                                                        />
                                                        <span>
                                                            This range will be
                                                            used for dynamic
                                                            VLANs if overlapping
                                                            subscriptions is
                                                            enabled. It will
                                                            also be used to
                                                            assign VLANs to
                                                            units when adding a
                                                            unit if you do not
                                                            provide a VLAN when
                                                            adding a unit.
                                                        </span>
                                                    </MDBTooltip>
                                                    <MDBRow>
                                                        <MDBCol col="6">
                                                            <input
                                                                required
                                                                name="minVlanRes"
                                                                id="minVlanRes"
                                                                className="form-control"
                                                                onChange={
                                                                    this
                                                                        .changeHandler
                                                                }
                                                                value={
                                                                    this.state
                                                                        .minVlanRes
                                                                }
                                                                disabled={
                                                                    this.state
                                                                        .disabled
                                                                }
                                                            />
                                                        </MDBCol>
                                                        <div className="invalid-feedback">
                                                            Please provide a
                                                            valid number
                                                        </div>
                                                        <MDBCol
                                                            col="6"
                                                            className="dash"
                                                        >
                                                            <input
                                                                required
                                                                name="maxVlanRes"
                                                                id="maxVlanRes"
                                                                className="form-control"
                                                                onChange={
                                                                    this
                                                                        .changeHandler
                                                                }
                                                                value={
                                                                    this.state
                                                                        .maxVlanRes
                                                                }
                                                                disabled={
                                                                    this.state
                                                                        .disabled
                                                                }
                                                            />
                                                        </MDBCol>
                                                    </MDBRow>
                                                    <div className="invalid-feedback">
                                                        Please provide a valid
                                                        number
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol
                                                    size="12"
                                                    md="12"
                                                    lg="6"
                                                >
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Guest Devices VLAN Range
                                                        *
                                                    </label>
                                                    <MDBTooltip
                                                        material
                                                        placement="left"
                                                        className="rightTooltip"
                                                        style={{
                                                            display: "block",
                                                        }}
                                                    >
                                                        <MDBIcon
                                                            far
                                                            icon="question-circle"
                                                        />
                                                        <span>
                                                            Not Yet In Use: In a
                                                            future update, this
                                                            range will be used
                                                            to dynamically
                                                            assign VLANs to
                                                            Guest devices
                                                        </span>
                                                    </MDBTooltip>
                                                    <MDBRow>
                                                        <MDBCol col="6">
                                                            <input
                                                                required
                                                                name="minVlanGuest"
                                                                id="minVlanGuest"
                                                                className="form-control"
                                                                onChange={
                                                                    this
                                                                        .changeHandler
                                                                }
                                                                value={
                                                                    this.state
                                                                        .minVlanGuest
                                                                }
                                                                disabled={
                                                                    this.state
                                                                        .disabled
                                                                }
                                                            />
                                                            <div className="invalid-feedback">
                                                                Please provide a
                                                                valid number
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol
                                                            col="6"
                                                            className="dash"
                                                        >
                                                            <input
                                                                required
                                                                name="maxVlanGuest"
                                                                id="maxVlanGuest"
                                                                className="form-control"
                                                                onChange={
                                                                    this
                                                                        .changeHandler
                                                                }
                                                                value={
                                                                    this.state
                                                                        .maxVlanGuest
                                                                }
                                                                disabled={
                                                                    this.state
                                                                        .disabled
                                                                }
                                                            />
                                                            <div className="invalid-feedback">
                                                                Please provide a
                                                                valid number
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCol>
                                                <MDBCol
                                                    size="12"
                                                    md="12"
                                                    lg="6"
                                                >
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Roaming Devices VLAN
                                                        Range *
                                                    </label>
                                                    <MDBTooltip
                                                        material
                                                        placement="left"
                                                        className="rightTooltip"
                                                        style={{
                                                            display: "block",
                                                        }}
                                                    >
                                                        <MDBIcon
                                                            far
                                                            icon="question-circle"
                                                        />
                                                        <span>
                                                            Not Yet In Use: In a
                                                            future update, this
                                                            range will be used
                                                            to dynamically
                                                            assign VLANs to
                                                            roaming devices
                                                        </span>
                                                    </MDBTooltip>
                                                    <MDBRow>
                                                        <MDBCol col="6">
                                                            <input
                                                                required
                                                                name="minVlanRoaming"
                                                                id="minVlanRoaming"
                                                                className="form-control"
                                                                onChange={
                                                                    this
                                                                        .changeHandler
                                                                }
                                                                value={
                                                                    this.state
                                                                        .minVlanRoaming
                                                                }
                                                                disabled={
                                                                    this.state
                                                                        .disabled
                                                                }
                                                            />
                                                            <div className="invalid-feedback">
                                                                Please provide a
                                                                valid number
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol
                                                            col="6"
                                                            className="dash"
                                                        >
                                                            <input
                                                                required
                                                                name="maxVlanRoaming"
                                                                id="maxVlanRoaming"
                                                                className="form-control"
                                                                onChange={
                                                                    this
                                                                        .changeHandler
                                                                }
                                                                value={
                                                                    this.state
                                                                        .maxVlanRoaming
                                                                }
                                                                disabled={
                                                                    this.state
                                                                        .disabled
                                                                }
                                                            />
                                                            <div className="invalid-feedback">
                                                                Please provide a
                                                                valid number
                                                            </div>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol col="6" sm="12" lg="12">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        NAS Identifier{" "}
                                                        <MDBIcon
                                                            icon="copy"
                                                            onClick={
                                                                this.copyNASID
                                                            }
                                                        />
                                                    </label>
                                                    <input
                                                        disabled
                                                        name="nasID"
                                                        id="nasID"
                                                        className="form-control"
                                                        value={this.state.nasID}
                                                    />
                                                    <span id="passwordHidden">
                                                        {this.state.nasID}
                                                    </span>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol
                                                    col="12"
                                                    sm="12"
                                                    lg="12"
                                                >
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Venue ID{" "}
                                                        <MDBIcon
                                                            icon="copy"
                                                            onClick={
                                                                this.copyVenueID
                                                            }
                                                        />
                                                    </label>
                                                    <input
                                                        disabled
                                                        id="venueID"
                                                        name="venueID"
                                                        className="form-control"
                                                        value={
                                                            this.state
                                                                .selectedID
                                                        }
                                                    />
                                                    <span id="venueIDHidden">
                                                        {this.state.selectedID}
                                                    </span>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol
                                                    col="12"
                                                    sm="12"
                                                    lg="12"
                                                >
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        External Venue ID{" "}
                                                        <MDBIcon
                                                            icon="copy"
                                                            onClick={
                                                                this
                                                                    .copyExternalVenueID
                                                            }
                                                        />
                                                    </label>
                                                    <input
                                                        disabled
                                                        id="nasID"
                                                        name="nasID"
                                                        className="form-control"
                                                        value={
                                                            this.state
                                                                .externalVenueID
                                                        }
                                                    />
                        <span id="externalVenueIDHidden">{this.state.externalVenueID}</span>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol col="6" sm="12" lg="6">
                                                    <MDBInput
                                                        label="Send email for added subscriptions?"
                                                        type="checkbox"
                                                        id="sendSubscriptionAddedEmail"
                                                        name="sendSubscriptionAddedEmail"
                                                        onChange={
                                                            this
                                                                .toggleCheckboxValuesendSubscriptionAddedEmail
                                                        }
                                                        checked={
                                                            this.state
                                                                .sendSubscriptionAddedEmail
                                                        }
                                                    />

                                                    <MDBInput
                                                        label="Send email for ended subscriptions?"
                                                        type="checkbox"
                                                        id="sendSubscriptionEndEmail"
                                                        name="sendSubscriptionEndEmail"
                                                        onChange={
                                                            this
                                                                .toggleCheckboxValuesendSubscriptionEndedEmail
                                                        }
                                                        checked={
                                                            this.state
                                                                .sendSubscriptionEndEmail
                                                        }
                                                    />
                                                </MDBCol>
                                                <MDBCol col="6" sm="12" lg="6">
                                                    <MDBInput
                                                        label="Allow Overlapping Subscriptions"
                                                        type="checkbox"
                                                        id="allowOverlapping"
                                                        name="allowOverlapping"
                                                        onChange={
                                                            this
                                                                .toggleCheckboxValueAllowOverlapping
                                                        }
                                                        checked={
                                                            this.state
                                                                .allowOverlapping
                                                        }
                                                    >
                                                        <MDBTooltip
                                                            material
                                                            placement="left"
                                                            className="rightTooltip"
                                                            style={{
                                                                display:
                                                                    "block",
                                                            }}
                                                        >
                                                            <MDBIcon
                                                                far
                                                                icon="question-circle"
                                                            />
                                                            <span>
                                                                Allow multiple
                                                                subscribers to
                                                                sign up for
                                                                service in the
                                                                same unit. If
                                                                enabled, a
                                                                temporary unit
                                                                on a dynamic
                                                                VLAN from the
                                                                Residential
                                                                Devices Range
                                                                will be created
                                                                while the
                                                                subscription is
                                                                overlapping.
                                                            </span>
                                                        </MDBTooltip>
                                                    </MDBInput>
                                                    <MDBInput
                                                        label="Enable Broadband Label?"
                                                        type="checkbox"
                                                        id="displayFCCLabel"
                                                        name="displayFCCLabel"
                                                        onChange={
                                                            this
                                                                .toggleCheckboxValueDisplayFCCLabel
                                                        }
                                                        checked={
                                                            this.state
                                                                .displayFCCLabel
                                                        }
                                                        disabled={
                                                            this.state.disabled
                                                        }
                                                    >
                                                        <MDBTooltip
                                                            material
                                                            placement="left"
                                                            className="rightTooltip"
                                                            style={{
                                                                display:
                                                                    "block",
                                                            }}
                                                        >
                                                            <MDBIcon
                                                                far
                                                                icon="question-circle"
                                                            />
                                                            <span>
                                                                Enabling will
                                                                show broadband
                                                                labels for all
                                                                service plans at
                                                                the venue in the
                                                                shopping cart
                                                                and subscriber
                                                                account settings
                                                            </span>
                                                        </MDBTooltip>
                                                    </MDBInput>
                                                </MDBCol>
                                            </MDBRow>
                                            <br />
                                            <MDBRow>
                                                <MDBCol col="6" sm="12" lg="6">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Typical Latency
                                                    </label>
                                                    <MDBTooltip
                                                        material
                                                        placement="left"
                                                        className="rightTooltip"
                                                        style={{
                                                            display: "block",
                                                        }}
                                                    >
                                                        <MDBIcon
                                                            far
                                                            icon="question-circle"
                                                        />
                                                        <span>
                                                            The expected latency
                                                            for subscribers.
                                                            This field is
                                                            required if the
                                                            broadband label is
                                                            enabled.
                                                        </span>
                                                    </MDBTooltip>
                                                    <input
                                                        // required
                                                        name="typicalLatency"
                                                        id="typicalLatency"
                                                        className="form-control"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        value={
                                                            this.state
                                                                .typicalLatency
                                                        }
                                                        // disabled={!this.state.displayFCCLabel}
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please provide a valid
                                                        number
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <br />
                                            <div className="text-right submitButton">
                                                <MDBBtn
                                                    color="danger"
                                                    type="submit"
                                                    disabled={
                                                        this.state.disabled
                                                    }
                                                >
                                                    {this.props.loading ===
                                                    true ? (
                                                        <div
                                                            className="spinner-border spinner-border-sm"
                                                            role="status"
                                                        >
                                                            <span className="sr-only">
                                                                Loading...
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        "Save Changes"
                                                    )}
                                                </MDBBtn>
                                            </div>
                                        </form>
                                    </>
                                )
                            }
                        </MDBTabPane>
                        <MDBTabPane tabId="2" role="tabpanel">
                            <ViewVenueUnits
                                venueID={this.props.venueID}
                                venueName={this.state.venueName}
                                sharedSecret={this.props.sharedSecret}
                                updateName={this.props.updateName}
                                getVenueDetails={this.props.getVenueDetails}
                                unitsList={this.props.unitsArrayAll}
                                unitsArrayAll={this.props.unitsArrayAll}
                                availablePlans={this.props.servicePlans}
                                UnitsBuildingFloorListSusbcribers={
                                    this.props.UnitsBuildingFloorListSusbcribers
                                }
                                attributeForm={this.props.attributeForm}
                                loadingVenueDetails={
                                    this.props.loadingVenueDetails
                                }
                                hasUpdated={this.props.hasUpdated}
                                optionTemplateVenues={
                                    this.state.optionTemplateVenues
                                }
                                handleSelectVenue={this.handleSelectVenue}
                                address1={this.state.address1}
                                address2={this.state.address2}
                                city={this.state.city}
                                state={this.state.region}
                                postalcode={this.state.postalcode}
                                country={this.state.country}
                                optionTemplateServicePlans={
                                    this.state.optionTemplateServicePlans
                                }
                                keypoolList={this.state.keypoolList}
                            />
                        </MDBTabPane>
                        <MDBTabPane tabId="3" role="tabpanel">
                            <ViewVenueBandwidthUsage
                                venueID={this.props.venueID}
                            />
                        </MDBTabPane>
                        <MDBTabPane tabId="4" role="tabpanel">
                            <ViewVenueServicePlans
                                venueID={this.props.venueID}
                                servicePlans={this.props.servicePlans}
                                disablePlanConfirm={this.disablePlanConfirm}
                                venueName={this.state.venueName}
                                sharedSecret={this.props.sharedSecret}
                                updateName={this.props.updateName}
                                getVenueDetails={this.props.getVenueDetails}
                                disable_serviceplan={
                                    this.state.disable_serviceplan
                                }
                                selectDefaultServicePlanResident={
                                    this.state.selectDefaultServicePlanResident
                                }
                                selectDefaultServicePlanWireless={
                                    this.state.selectDefaultServicePlanWireless
                                }
                            />
                        </MDBTabPane>
                        <MDBTabPane tabId="5" role="tabpanel">
                            <ViewVenueKeypools
                                venueID={this.props.venueID}
                                venueName={this.state.venueName}
                                keyPoolList={this.props.keyPoolList}
                                updateName={this.props.updateName}
                                getVenueDetails={this.props.getVenueDetails}
                            />
                        </MDBTabPane>
                        <MDBTabPane tabId="6" role="tabpanel">
                            <ViewVenueAdministrators
                                venueID={this.props.venueID}
                                venueName={this.state.venueName}
                                staffArrayAll={this.props.staffArrayAll}
                                updateName={this.props.updateName}
                                getVenueDetails={this.props.getVenueDetails}
                                sharedSecret={this.props.sharedSecret}
                            />
                        </MDBTabPane>
                        <MDBTabPane tabId="7" role="tabpanel">
                            <ViewVenueEquipment
                                venueID={this.props.venueID}
                                venueName={this.state.venueName}
                                venueEquipList={this.props.venueEquipList}
                                updateName={this.props.updateName}
                                getVenueDetails={this.props.getVenueDetails}
                                sharedSecret={this.props.sharedSecret}
                            />
                        </MDBTabPane>
                        <MDBTabPane tabId="8" role="tabpanel">
                            <div className="logList">
                                {this.state.venueLog !== undefined ? (
                                    // console.log(this.state.subscriberLog)
                                    <div>
                                        <JSONPretty
                                            id="json-pretty"
                                            data={this.state.venueLog}
                                        ></JSONPretty>
                                    </div>
                                ) : (
                                    <div
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                )}
                            </div>
                        </MDBTabPane>
                        <MDBTabPane tabId="9" role="tabpanel">
                            <form
                                className="needs-validation"
                                onSubmit={this.modifyVenueInfo}
                                noValidate
                            >
                                <MDBInput
                                    label="Enable shopping cart?"
                                    type="checkbox"
                                    id="enableShoppingCart"
                                    name="enableShoppingCart"
                                    onChange={
                                        this
                                            .toggleCheckboxValueEnableShoppingCart
                                    }
                                    checked={this.state.enableShoppingCart}
                                    disabled={this.state.disabled}
                                >
                                    <MDBTooltip
                                        material
                                        placement="left"
                                        className="rightTooltip"
                                        style={{ display: "block" }}
                                    >
                                        <MDBIcon far icon="question-circle" />
                                        <span>
                                            The venue will display in your
                                            shopping cart for self sign up.
                                        </span>
                                    </MDBTooltip>
                                </MDBInput>
                                <br />
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Shopping Cart Price Discount (
                                            {this.props.currencyName})
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                Apply a discount to all service
                                                plan billing cycle prices at
                                                this venue.
                                            </span>
                                        </MDBTooltip>
                                        <input
                                            type="number"
                                            step="0.01"
                                            id="shoppingCartPriceAdjustment"
                                            name="shoppingCartPriceAdjustment"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={
                                                -Math.abs(
                                                    this.state
                                                        .shoppingCartPriceAdjustment
                                                )
                                            }
                                            disabled={this.state.disabled}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a value.
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="6">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Trial Cycles Adjustments
                                        </label>
                                        <MDBTooltip
                                            material
                                            placement="left"
                                            className="rightTooltip"
                                            style={{ display: "block" }}
                                        >
                                            <MDBIcon
                                                far
                                                icon="question-circle"
                                            />
                                            <span>
                                                Adjust trial cycles for all
                                                services plans at this venue.
                                            </span>
                                        </MDBTooltip>
                                        <SelectSearch
                                            id="selectTrialCycles"
                                            search={true}
                                            filterOptions={fuzzySearch}
                                            options={
                                                this.state.trialCyclesOptions
                                            }
                                            inputId="trialCycleArray"
                                            classNamePrefix="unit-select form-control"
                                            onChange={
                                                this.handleSelectTrialCycles
                                            }
                                            value={
                                                this.state.selectedTrialCycles
                                            }
                                            placeholder="Please select..."
                                            disabled={this.state.disabled}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a value
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <br />
                                <MDBRow>
                                    <MDBCol col="12" sm="12" lg="12">
                                        <p>
                                            <label
                                                htmlFor="defaultFormLoginEmailEx"
                                                className="black-text"
                                            >
                                                Stripe Configuration Override
                                            </label>
                                            <MDBTooltip
                                                material
                                                placement="left"
                                                className="rightTooltip"
                                                style={{ display: "block" }}
                                            >
                                                <MDBIcon
                                                    far
                                                    icon="question-circle"
                                                />
                                                <span>
                                                    Apply venue specific Stripe
                                                    credentials. This will
                                                    override Network Operator
                                                    Stripe credentials for this
                                                    venue. WARNING: If there are
                                                    existing subscribers at the
                                                    venue when changing these
                                                    values, payments for those
                                                    subscribers will not
                                                    process.
                                                </span>
                                            </MDBTooltip>
                                            <br />
                                            <MDBIcon
                                                icon="lock"
                                                onClick={this.unlockStripeKeys}
                                                id="unlockStripeKeys"
                                            />{" "}
                                            (Click to unlock/lock)
                                        </p>
                                        <div className="borderBoxRounded">
                                            <MDBRow>
                                                <MDBCol col="6" sm="12" lg="6">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Stripe Publishable Key
                                                    </label>
                                                    <input
                                                        disabled="true"
                                                        id="stripePublishableKey"
                                                        name="stripePublishableKey"
                                                        className="form-control"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        value={
                                                            this.state
                                                                .stripePublishableKey
                                                        }
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please provide a value.
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol col="6" sm="12" lg="6">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Stripe Secret Key
                                                    </label>
                                                    <input
                                                        disabled="true"
                                                        id="stripeSecretKey"
                                                        name="stripeSecretKey"
                                                        className="form-control"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        value={
                                                            this.state
                                                                .stripeSecretKey
                                                        }
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please provide a value.
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                            <MDBRow>
                                                <MDBCol col="6" sm="12" lg="6">
                                                    <label
                                                        htmlFor="defaultFormLoginEmailEx"
                                                        className="black-text"
                                                    >
                                                        Stripe Webhook Signing
                                                        Secret
                                                    </label>
                                                    <input
                                                        disabled="true"
                                                        id="stripeWebhookSigningSecret"
                                                        name="stripeWebhookSigningSecret"
                                                        className="form-control"
                                                        onChange={
                                                            this.changeHandler
                                                        }
                                                        value={
                                                            this.state
                                                                .stripeWebhookSigningSecret
                                                        }
                                                    />
                                                    <div className="invalid-feedback">
                                                        Please provide a value.
                                                    </div>
                                                </MDBCol>
                                            </MDBRow>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                                <div className="text-right submitButton">
                                    <MDBBtn
                                        color="danger"
                                        type="submit"
                                        disabled={this.state.disabled}
                                    >
                                        {this.props.loading === true ? (
                                            <div
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                            >
                                                <span className="sr-only">
                                                    Loading...
                                                </span>
                                            </div>
                                        ) : (
                                            "Save Changes"
                                        )}
                                    </MDBBtn>
                                </div>
                            </form>
                        </MDBTabPane>
                        <MDBTabPane tabId="10" role="tabpanel">
                            <form
                                className="needs-validation"
                                onSubmit={this.modifyVenueInfo}
                                noValidate
                            >
                                <p className="mb-0">Realpage Sync Options:</p>
                                <MDBInput
                                    label="Download unit data (once a day)"
                                    type="radio"
                                    id="enableRealPageSyncFalse"
                                    name="Download unit data (once a day)"
                                    onClick={this.changeHandlerRadio(false)}
                                    checked={
                                        this.state.enableRealPageSync === false
                                            ? true
                                            : false
                                    }
                                    disabled={this.state.disabled}
                                />
                                <MDBInput
                                    label="Download units and subscriber data (once a day)"
                                    type="radio"
                                    id="enableRealPageSyncTrue"
                                    name="Download units and subscriber data (once a day)"
                                    onClick={this.changeHandlerRadio(true)}
                                    checked={
                                        this.state.enableRealPageSync === true
                                            ? true
                                            : false
                                    }
                                    disabled={this.state.disabled}
                                />
                                <br />
                                <p>{this.state.enableSyncMessage}</p>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            RealPage Site ID
                                        </label>
                                        <input
                                            id="realpageSiteID"
                                            name="realpageSiteID"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.realpageSiteID}
                                            disabled={this.state.disabled}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a value.
                                        </div>
                                    </MDBCol>
                                    <MDBCol col="6"></MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            RealPage PMC ID
                                        </label>
                                        <input
                                            id="realpagePMCID"
                                            name="realpagePMCID"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.realpagePMCID}
                                            disabled={this.state.disabled}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a value.
                                        </div>
                                    </MDBCol>
                                    <MDBCol col="6"></MDBCol>
                                </MDBRow>
                                <MDBRow>
                                    <MDBCol col="6" sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            RealPage Sync Date
                                        </label>
                                        <input
                                            disabled
                                            id="realpageSyncDate"
                                            name="realpageSyncDate"
                                            className="form-control"
                                            onChange={this.changeHandler}
                                            value={this.state.realpageSyncDate}
                                        />
                                        <div className="invalid-feedback">
                                            Please provide a value.
                                        </div>
                                    </MDBCol>
                                    <MDBCol col="6"></MDBCol>
                                </MDBRow>
                                <div className="text-right submitButton">
                                    <MDBBtn
                                        color="danger"
                                        type="submit"
                                        disabled={this.state.disabled}
                                    >
                                        {this.props.loading === true ? (
                                            <div
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                            >
                                                <span className="sr-only">
                                                    Loading...
                                                </span>
                                            </div>
                                        ) : (
                                            "Save Changes"
                                        )}
                                    </MDBBtn>
                                </div>
                            </form>
                        </MDBTabPane>
                        <MDBTabPane tabId="11" role="tabpanel">
                            <div className="searchForm">
                                <h4>Search for Transactions</h4>
                                <form
                                    className="needs-validation"
                                    onSubmit={this.findTransactions}
                                    noValidate
                                >
                                    <MDBRow>
                                        <MDBCol col="6">
                                            <label
                                                htmlFor="defaultFormLoginEmailEx"
                                                className="black-text"
                                            >
                                                Start Date
                                            </label>
                                            <DateInput
                                                dateFormat="MM/DD/YYYY"
                                                name="dateStart"
                                                placeholder="MM/DD/YYYY"
                                                value={this.state.dateStart}
                                                iconPosition="left"
                                                onChange={this.handleChange}
                                                className="dateTimePicker"
                                                maxDate={new Date(tomorrow)}
                                                required="false"
                                            />
                                        </MDBCol>
                                        <MDBCol col="6">
                                            <label
                                                htmlFor="defaultFormLoginEmailEx"
                                                className="black-text"
                                            >
                                                End Date
                                            </label>
                                            <DateInput
                                                dateFormat="MM/DD/YYYY"
                                                name="dateEnd"
                                                placeholder="MM/DD/YYYY"
                                                value={this.state.dateEnd}
                                                iconPosition="left"
                                                onChange={this.handleChange}
                                                className="dateTimePicker"
                                                minDate={
                                                    new Date(
                                                        this.state.dateStart
                                                    )
                                                }
                                                maxDate={new Date(tomorrow)}
                                                required="false"
                                            />
                                        </MDBCol>
                                    </MDBRow>
                                    <br />
                                    {this.state.transactions !== undefined ? (
                                        <>
                                            {this.state.loading === true ? (
                                                <MDBSpinner small />
                                            ) : (
                                                <TransactionsTable
                                                    transactions={
                                                        this.state.transactions
                                                    }
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {this.state.loading === true ? (
                                                <MDBSpinner small />
                                            ) : (
                                                <TransactionsTable
                                                    transactions={
                                                        this.state.transactions
                                                    }
                                                />
                                            )}
                                        </>
                                    )}
                                    <div className="text-right submitButton">
                                        <MDBBtn color="danger" type="submit">
                                            Find Transactions
                                        </MDBBtn>
                                    </div>
                                </form>
                            </div>
                        </MDBTabPane>
                        <MDBTabPane tabId="12" role="tabpanel">
                            <div>
                                <h4>System Data</h4>
                                <MDBRow>
                                    <MDBCol col="12" sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Venue ID
                                        </label>
                                        <input
                                            disabled
                                            id="venueID"
                                            name="venueID"
                                            className="form-control"
                                            value={this.state.selectedID}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <br />
                                <MDBRow>
                                    <MDBCol col="12" sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            Date Added
                                        </label>
                                        <input
                                            disabled
                                            id="dateAdded"
                                            name="dateAdded"
                                            className="form-control"
                                            value={this.state.dateAdded}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <br />
                                <MDBRow>
                                    <MDBCol col="12" sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            External Venue ID
                                        </label>
                                        <input
                                            disabled
                                            id="nasID"
                                            name="nasID"
                                            className="form-control"
                                            value={this.state.externalVenueID}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <br />
                                <MDBRow>
                                    <MDBCol col="12" sm="12" lg="12">
                                        <label
                                            htmlFor="defaultFormLoginEmailEx"
                                            className="black-text"
                                        >
                                            NAS-Identifier
                                        </label>
                                        <input
                                            disabled
                                            id="nasID"
                                            name="nasID"
                                            className="form-control"
                                            value={this.state.nasID}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        </MDBTabPane>
                    </MDBTabContent>
                </MDBModalBody>
            </>
        );
    }
}
export default withTranslation()(AddNewResident);
