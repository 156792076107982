/* Login screen */

import React, { useState, useEffect, Suspense } from "react";

import axios from "../../axios";

import { useHistory } from "react-router-dom";

import queryString from "query-string";

import Footer from "../../components/Common/Footer";

import brandsData from "../../brands/common/brands.json";


let LoginPage;

let LogoLogin;

let LogoLoginMobile;

let BrandName;

let FlagSignUp;

let FlagFooter;

  
export const SUB_PAGES = brandsData.forEach(({ name, url, logo_login, logo_mobile, component_directory_name, custom_component_directory_name, brand_directory_name, flag_signup, flag_footer_login }) => {
    if (url.includes(window.location.hostname)) {
        import("../../brands/" + brand_directory_name + "/css/style.scss");
        LoginPage = React.lazy(() =>
            import(
                "../../brands/" + custom_component_directory_name + "/components/Login/Login"
            )
        );
        LogoLogin = "/" + brand_directory_name + "/" + logo_login;
        LogoLoginMobile = "/" + brand_directory_name + "/" + logo_mobile;
        BrandName = name;
        FlagSignUp = flag_signup;
        FlagFooter = flag_footer_login;
    }
});

export default function Login() {
    const authToken = queryString.parse(window.location.search);
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const setWindowWidth = window.innerWidth;

    useEffect(() => {
        const handleWindowResize = () => {
            // setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    });

    const [email, setEmail] = useState({
        email: localStorage.getItem("email"),
    });

    const [password, setPassword] = useState("");
    const [state, setState] = useState({
        message: undefined,
        toggle: true,
        modal: window.location.pathname === "/terms" ? true : false,
        modalReset: window.location.pathname === "/reset" ? true : false,
        modalResetConfirm:
            window.location.pathname === "/reset_confirm" ? true : false,
        modalFAQ: window.location.pathname === "/help" ? true : false,
        focus: false
    });
    const history = useHistory();
    const [rememberMe, setChecked] = useState(
        sessionStorage.getItem("rememberMe") === null ? false : true
    );
    const handleClick = () => setChecked(!rememberMe);
    function validateForm(event) {

     event.preventDefault();
        // return (
        //     (email.length > 0 && password.length >= 12) ||
        //     (email["email"] !== "" && password.length >= 12)
        // );
        if (password.length < 12) {
         var h = document.getElementById("overlayPasswordCheck");
         h.style.display = "block";
         setState({
             message:
                 "Please double check your e-mail address and confirm your password is 12 characters or more.",
         });

         // state.message = undefined;
     } else {
      handleSubmit();
     }
    }
    if (
        authToken.authorizationToken !== undefined &&
        state.messageAuthToken === undefined
    ) {
        const data = {
            authorizationToken: authToken.authorizationToken,
        };
        axios
            .post("/subscriberconsole/login", data, {
                headers: {},
            })
            .then((response) => {
             let loginURL;
              if(window.location.hostname === "wifi.hyperleapnetwork.com" ||
                window.location.hostname === "devdd01.wifikey.link" 
                || window.location.hostname === "localhost" )
                {

                loginURL = "/";
                }else{

                loginURL = window.location.pathname;
                }
                sessionStorage.setItem("loginPage", loginURL);
                setState({ modalResetConfirm: false });
                sessionStorage.setItem(
                    "AccessToken",
                    response.data.AuthenticationResult.AuthenticationResult
                        .AccessToken
                );
                sessionStorage.setItem("fromAuthToken", true);
                let adminOf = [];
                let venueAdmin = [];
                let networkAdmin = [];
                let leasingAgent = [];
                let accountManager = [];
                let customerSupport = [];
                if (response.data.AdministrativeAccess !== undefined) {
                    adminOf = Object.keys(response.data.AdministrativeAccess);
                    accountManager = Object.keys(
                        response.data.AdministrativeAccess.NetworkOperatorAccountManager
                    ).length;
                    venueAdmin = Object.keys(response.data.AdministrativeAccess.VenueAdministrator).length;
                    networkAdmin = Object.keys(
                        response.data.AdministrativeAccess
                            .NetworkOperatorAdministrator
                    ).length;
                    leasingAgent = Object.keys(
                        response.data.AdministrativeAccess.LeasingAgent
                    ).length;
                    customerSupport = Object.keys(
                        response.data.AdministrativeAccess.CustomerSupport).length;
                    
                    sessionStorage.setItem("isAdmin", true);
                    sessionStorage.setItem("venueAdmin", venueAdmin);
                    sessionStorage.setItem("accountManager", accountManager);
                    sessionStorage.setItem("networkAdmin", networkAdmin);
                    sessionStorage.setItem("leasingAgent", leasingAgent);
                    sessionStorage.setItem("customerSupport", customerSupport);
                    sessionStorage.setItem("adminOf", adminOf.toString());

                    sessionStorage.setItem("i18next", "en-US", {
                        path: "/",
                        secure: true,
                        sameSite: "none",
                        httpOnly: false,
                    });
                } else {
                    sessionStorage.setItem("adminOf", adminOf.toString());
                    sessionStorage.setItem("isAdmin", false);
                }
                if (response.data.Administrator !== undefined) {
                    let domainList = [];
                    domainList = response.data.Administrator.NetworkOperators;
                    sessionStorage.setItem(
                        "domain",
                        JSON.stringify(domainList)
                    );
                }
                sessionStorage.setItem(
                    "AccessToken",
                    response.data.AuthenticationResult.AuthenticationResult
                        .AccessToken
                );
                sessionStorage.setItem(
                    "RefreshToken",
                    response.data.AuthenticationResult.AuthenticationResult
                        .RefreshToken
                );

                sessionStorage.setItem("firstName", response.data.GivenName, {
                    path: "/",
                    secure: true,
                    sameSite: "none",
                    httpOnly: false,
                });
                sessionStorage.setItem("lastName", response.data.FamilyName, {
                    path: "/",
                    secure: true,
                    sameSite: "none",
                    httpOnly: false,
                });
                sessionStorage.setItem("email", response.data.EmailAddress, {
                    path: "/",
                    secure: true,
                    sameSite: "none",
                    httpOnly: false,
                });
                sessionStorage.setItem(
                    "phoneNumber",
                    response.data["PhoneNumber.E164"],
                    {
                        path: "/",
                        secure: true,
                        sameSite: "none",
                        httpOnly: false,
                    }
                );

                history.push("/myaccount");
            })
            .catch((error) => {
                setState({
                    messageAuthToken:
                        "Authentication failure - please contact support",
                });
            });
    }
    function handleSubmit(event) {
     console.log("submitted")
        // event.preventDefault();
        let email1;
        if (email["email"] !== undefined) {
            email1 = email["email"];
        } else {
            email1 = email;
        }
        const data = {
            EmailAddress: email1,
            Password: password,
        };
        axios
            .post("/subscriberconsole/login", data, {
                headers: {},
            })
            .then((response) => {
                setState({ modalResetConfirm: false });
                let loginURL;
              if(window.location.hostname === "wifi.hyperleapnetwork.com" ||
                window.location.hostname === "devdd01.wifikey.link" 
                || window.location.hostname === "localhost" )
                {

                loginURL = "/";
                }else{

                loginURL = window.location.pathname;
                }
                sessionStorage.setItem("loginPage", loginURL);
                sessionStorage.setItem(
                    "AccessToken",
                    response.data.AuthenticationResult.AuthenticationResult
                        .AccessToken
                );
                sessionStorage.setItem("userEmail", email1);
                var h = document.getElementById("overlay");
                setTimeout(function () {
                 h.style.display = "block";
                 setState({ message: "Please wait..." });
             }, 3000);
                let adminOf = [];
                let venueAdmin = [];
                let networkAdmin = [];
                let leasingAgent = [];
                let accountManager = [];
                let customerSupport = [];
                if (response.data.AdministrativeAccess !== undefined) {
                    adminOf = Object.keys(response.data.AdministrativeAccess);
                    accountManager = Object.keys(
                        response.data.AdministrativeAccess.NetworkOperatorAccountManager
                    ).length;
                    venueAdmin = Object.keys(response.data.AdministrativeAccess.VenueAdministrator).length;
                    networkAdmin = Object.keys(
                        response.data.AdministrativeAccess.NetworkOperatorAdministrator
                    ).length;
                    leasingAgent = Object.keys(
                        response.data.AdministrativeAccess.LeasingAgent
                    ).length;
                    customerSupport = Object.keys(
                        response.data.AdministrativeAccess.CustomerSupport).length;
                        if (
                         venueAdmin > 0 ||
                         leasingAgent > 0 ||
                         accountManager > 0
                     ) {
                setTimeout(function () {
                 h.style.display = "block";
                 setState({ message: "Please wait..." });
                 
             }, 3000);
             history.go("/dashboard");
             window.location.href = '/dashboard';
             console.log("/dashboard");   
                     } else if (
                         networkAdmin > 0 ||
                         customerSupport > 0
                     ) {
                      setTimeout(function () {
                       h.style.display = "block";
                       setState({ message: "Please wait..." });
                       
                   }, 3000);
                   history.go("/networkdashboard");
                   window.location.href = '/networkdashboard';
                   console.log("/networkdashboard");
                     } else {
                      setTimeout(function () {
                       h.style.display = "block";
                       setState({ message: "Please wait..." });
                       
                   }, 3000);
                   history.go("/myaccount");
                   window.location.href = '/myaccount';
                   console.log("/myaccount");
                     }
                    sessionStorage.setItem("isAdmin", true);
                    sessionStorage.setItem("accountManager", accountManager);
                    sessionStorage.setItem("venueAdmin", venueAdmin);
                    sessionStorage.setItem("networkAdmin", networkAdmin);
                    sessionStorage.setItem("leasingAgent", leasingAgent);
                    sessionStorage.setItem("customerSupport", customerSupport);
                    sessionStorage.setItem("adminOf", adminOf.toString());

                    sessionStorage.setItem("i18next", "en-US", {
                        path: "/",
                        secure: true,
                        sameSite: "none",
                        httpOnly: false,
                    });
                } else {
                    sessionStorage.setItem("adminOf", adminOf.toString());
                    sessionStorage.setItem("isAdmin", false);
                }
                if (response.data.Administrator !== undefined) {
                    let domainList = [];
                    domainList = response.data.Administrator.NetworkOperators;
                    sessionStorage.setItem(
                        "domain",
                        JSON.stringify(domainList)
                    );
                }
                sessionStorage.setItem(
                    "AccessToken",
                    response.data.AuthenticationResult.AuthenticationResult
                        .AccessToken
                );
                sessionStorage.setItem(
                    "RefreshToken",
                    response.data.AuthenticationResult.AuthenticationResult
                        .RefreshToken
                );

                sessionStorage.setItem("firstName", response.data.GivenName);
                sessionStorage.setItem("lastName", response.data.FamilyName);
                sessionStorage.setItem("email", response.data.EmailAddress);
                sessionStorage.setItem(
                    "phoneNumber",
                    response.data["PhoneNumber.E164"]
                );
                if (rememberMe) {
                    sessionStorage.setItem("rememberMe", true);
                } else {
                    sessionStorage.removeItem("rememberMe");
                }
                
                
            })
            .catch((error) => {
             console.log(error)
             var h = document.getElementById("overlay");
             h.style.display = "block";
             setState({ message: "Invalid username or password" });
                setTimeout(function () {
                 
                

              window.location.reload();
          }, 3000);
                

            });
    }
    function toggleAddProfile() {
        setState({
            modal: !state.modal,
        });
        if(!state.modal === false){
            history.push("/");
        }
    }
    function toggleReset() {
        setState({
            modalReset: !state.modalReset,
        });
        if(!state.modalReset === false){
            history.push("/");
        }
    }
    function toggleResetConfirm() {
        setState({
            modalResetConfirm: !state.modalResetConfirm,
        });
        if(!state.modalResetConfirm === false){
            history.push("/");
        }
    }
    function toggleFAQ() {
        setState({
            modalFAQ: !state.modalFAQ,
        });
        if(!state.modalFAQ === false){
            history.push("/");
        }
    }
    if (state.message !== undefined || state.messageAuthToken !== undefined) {
        var h = document.getElementById("overlay");
        h.style.display = "block";
        setTimeout(function () {
            h.style.display = "block";
        }, 2000);
        setTimeout(() => {
            h.style.display = "none";
            
            state.message = undefined;
        }, 3000);
    }

    return (
        <>
            {authToken.authorizationToken !== undefined ? (
                <>
                    {state.messageAuthToken !== undefined ? (
                        <div id="overlay">
                            <div class="overlayMessage">
                                {state.messageAuthToken}
                            </div>
                        </div>
                    ) : (
                        <div id="overlay" style={{ display: "block" }}>
                            <div class="overlayMessage">
                                <div
                                    className="spinner-border spinner-border"
                                    role="status"
                                >
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <Suspense fallback={<div
                        className="overlay"
                        id="overlay"
                        style={{ display: "block" }}
                    >
                        <div className="overlayMessage">
                            <div
                                className="spinner-border spinner-border"
                                role="status"
                            >
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>}>
                    <div className="overlayPasswordCheck" id="overlayPasswordCheck">
                                        <div className="overlayMessage">
                                            {state.message}
                                        </div>
                                    </div>
                        <LoginPage
                            toggleAddProfile={toggleAddProfile}
                            toggleReset={toggleReset}
                            toggleResetConfirm={toggleResetConfirm}
                            toggleFAQ={toggleFAQ}
                            handleSubmit={handleSubmit}
                            validateForm={validateForm}
                            modalFAQ={state.modalFAQ}
                            modalReset={state.modalReset}
                            modal={state.modal}
                            modalResetConfirm={state.modalResetConfirm}
                            passwordShown={passwordShown}
                            togglePasswordVisiblity={togglePasswordVisiblity}
                            setEmail={setEmail}
                            email={email}
                            setPassword={setPassword}
                            password={password}
                            handleClick={handleClick}
                            rememberMe={rememberMe}
                            message={state.message}
                            brand={BrandName}
                            logo={LogoLogin}
                            logoMobile={LogoLoginMobile}
                            flag_signup={FlagSignUp}
                            focus={state.focus}
                        />
                        {
                            FlagFooter ? 
                                <Footer/> :
                                ""
                        }
                    </Suspense>
                </>
            )}
        </>
    );
}