import React from "react";

import { MDBDataTableV5 } from "mdbreact";

export default function Basic(props) {
    let userAttributes;

    let datatable;
    [datatable] = React.useState("");
    if (Object.keys(props.unitSubscriptionsList).length !== 0) {
        userAttributes = [];
        let Address2 =
            props.unitSubscriptionsList["AddressLine2"] !== ""
                ? props.unitSubscriptionsList["AddressLine2"] + "\n\r"
                : "";
        userAttributes.push({
            subName:
                props.unitSubscriptionsList["SubscriberGivenName"] +
                " " +
                props.unitSubscriptionsList["SubscriberFamilyName"],
            subAddress:
                props.unitSubscriptionsList["AddressLine1"] +
                "\n\r" +
                Address2 +
                props.unitSubscriptionsList["City"] +
                ", " +
                props.unitSubscriptionsList["State"] +
                " " +
                props.unitSubscriptionsList["PostalCode"],
            subPhone: props.unitSubscriptionsList["SubscriberPhoneNumber.E164"],
            subEmail: props.unitSubscriptionsList["SubscriberEmail"],
            subInService: props.unitSubscriptionsList["Status"],
            subStartDate:
                props.unitSubscriptionsList["StartDateTimeUTC.ISO8601"] !== ""
                    ? Intl.DateTimeFormat("en-CA", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "numeric",
                          minute: "numeric",
                      }).format(
                          new Date(
                              props.unitSubscriptionsList[
                                  "StartDateTimeUTC.ISO8601"
                              ]
                          )
                      )
                    : "",
            clickEvent: () => props.modifyResident(props.unitSubscriptionsList),
        });
    }

    datatable = {
        columns: [
            {
                label: "Subscriber Name",
                field: "subName",
            },
            {
                label: "Address",
                field: "subAddress",
            },
            {
                label: "Phone",
                field: "subPhone",
            },
            {
                label: "Email",
                field: "subEmail",
            },
            {
                label: "Service Status",
                field: "subInService",
            },
            {
                label: "Start Date",
                field: "subStartDate",
            },
        ],
        rows: userAttributes,
    };
    return (
        <MDBDataTableV5
            sortable={true}
            responsive
            hover
            data={datatable}
            searchTop={true}
            searchBottom={false}
            searchLabel="Search for Subscriber"
            paging={false}
            className="recentResidents"
            info={false}
            noRecordsFoundLabel="No Subscribers Found"
            sortRows={["subStartDate"]}
        />
    );
}
